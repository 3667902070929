<template v-if="order">
    <div id="details-order-page">
        <toolbar-back-button-component v-if="order.order_number" :title="'Commande ' + order.order_number"/>

        <div class="pa-4">
            <card-order-details-component v-if="order.order_number" :order="order" class="mb-2"/>

            <card-validate-order-component
                v-if="userHasAccess('ORDC',role_edit) && order.order_number && products"
                ref="cardValidateOrderComponent"
                :order="order" :products="products"
            />
        </div>
    </div>
</template>

<script>
import CardOrderDetailsComponent from "@/components/Order/CardOrderDetailsComponent";
import CardValidateOrderComponent from "@/components/Order/CardValidateOrderComponent";
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";
import OrderCockpitRepository from "@/repositories/OrderCockpitRepository";

export default {
    name: 'DetailsOrderCockpitPage',
    components: {ToolbarBackButtonComponent, CardValidateOrderComponent, CardOrderDetailsComponent},
    data() {
        return {
            order: {},
            products: []
        }
    },
    created() {
        this.getOrderDetails();
    },
    methods: {
        getOrderDetails() {
            OrderCockpitRepository.getOrderCockpitListById(this.$route.params.id).then((success) => {
                let order_cockpit = success.data.data
                if (order_cockpit.state === 1 && !order_cockpit.is_revoked) {
                    this.order = order_cockpit;
                    this.getProductsListByAsset(this.order.asset.id_asset);
                } else {
                    this.showSnackbar('error', this.$t('error_display.order_already_validated'));
                }
            }).catch((error) => {
                this.manageError(error);
            })
        },

        getProductsListByAsset(id_asset) {
            OrderCockpitRepository.getOrderCockpitProductsByIdAsset(id_asset).then((success) => {
                this.products = success.data.data;
                this.$refs["cardValidateOrderComponent"].selected_products = this.products.find(product => product.id_liquid === this.order.id_liquid);
                this.$refs['cardValidateOrderComponent'].desired_date_delivery = this.order.dates_limit.min_date.date;
            }).catch((error) => {
                this.manageError(error);
            })
        },
    },
}
</script>