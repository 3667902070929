<template>
    <div id="card-validate-order-component">
        <v-card elevation="0">
            <v-card-text>
                <v-select
                    v-model="selected_products"
                    :items="products"
                    :menu-props="{ bottom: true, offsetY: true }"
                    filled
                    item-text="name" item-value="id_liquid"
                    :label="$t('card_validate_order_component.product_to_order')"
                    return-object
                />

                <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedDate"
                            filled
                            :label="$t('card_validate_order_component.desired_delivery_date')"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"/>
                    </template>
                    <v-date-picker
                        v-if="order"
                        v-model="desired_date_delivery"
                        :min="order.dates_limit.min_date.date"
                        :max="order.dates_limit.max_date.date"
                        :allowed-dates="allowedDates"
                        @input="date_picker = false"
                    />
                </v-menu>

                <p class="font-weight-bold mb-1">{{ $t('card_validate_order_component.detail_order') }} :</p>

                <v-text-field
                    clearable
                    filled
                    :label="$t('card_validate_order_component.purchase_order_number')"
                    prepend-inner-icon="mdi-file"
                    v-model="purchase_order"
                />

                <v-file-input
                    accept="application/pdf,.csv,.xls,.doc,.docx"
                    filled
                    clearable
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    :label="$t('card_validate_order_component.join_order_form')"
                    v-model="purchase_order_file"
                />

                <v-textarea
                    filled
                    :label="$t('card_validate_order_component.comment')"
                    v-model="comment"
                />

                <div class="font-16 mb-2 " v-if="selected_products">
                    <div>
                        <span class="font-weight-bold">{{ $t('card_validate_order_component.price_in_m3') }} :</span>
                        <div>
                            <span class="float-left">{{selected_products.cotation_method}}</span>
                            <span v-if="selected_products.price" class="float-right">{{selected_products.price}} €</span>
                        </div>
                    </div>
                    <br>
                    <div class="mt-2" v-if="selected_products.price">
                        <span class="font-weight-bold font-16">{{ $t('card_validate_order_component.total_price') }} :</span>
                        <div >
                            <span>{{ priceCalculator }}</span>
                        </div>
                    </div>

                    <v-alert class="mt-2"
                            border="left"
                            dense
                            text
                            type="info"
                    ><span v-html="selected_products.cotation_method_message"></span></v-alert>
                </div>

                <v-btn
                    block
                    class="mb-2"
                    color="primary"
                    @click="dialog_overview_order = true"
                    :disabled="!valid"
                >
                    <span :class="!valid ? 'font-weight-bold' : 'font-weight-bold secondary--text'">
                        {{ $t('card_validate_order_component.valid_order') }}
                    </span>
                </v-btn>

                <v-btn
                    block
                    text
                    @click="dialog_cancel_order = true"
                >
                    <span class="font-weight-bold text-decoration-underline">
                        {{ $t('card_validate_order_component.decline_order') }}
                    </span>
                </v-btn>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_cancel_order" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="font-weight-bold text-uppercase font-18">
                        {{ $t('card_validate_order_component.cancel_order') }}
                    </p>
                </v-card-title>
                <v-card-text>
                    <p class="mb-0">
                        {{ $t('card_validate_order_component.cancel_this_order', {order_number: order.order_number}) }}
                    </p>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer/>
                    <v-btn
                        class="font-weight-bold"
                        text
                        @click="dialog_cancel_order = false"
                    >
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn
                        class="font-weight-bold"
                        text
                        @click="deleteOrder()"
                    >
                        <span class="secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_overview_order" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="font-weight-bold text-uppercase font-18">
                        {{ $t('card_validate_order_component.summary_order') }}
                    </p>
                </v-card-title>

                <v-card-text class="text-center">
                    <p class="mb-1">{{ $t('card_validate_order_component.order_number') }}<span class="font-weight-bold">{{ order.order_number }}</span></p>
                    <p class="mb-1">{{ $t('card_validate_order_component.asset') }}: <span class="font-weight-bold">{{ order.asset.name_asset }}</span></p>
                    <p class="mb-1">{{ $t('card_validate_order_component.locate') }}: <span class="font-weight-bold">{{ order.asset.address }}</span></p>
                    <p class="mb-1">{{ $t('card_validate_order_component.quantity') }}: <span class="font-weight-bold">{{ order.quantity }}m³</span></p>
                    <p class="mb-1">{{ $t('card_validate_order_component.product_order') }}:
                        <span class="font-weight-bold">{{ selected_products.name }}</span>
                    </p>
                    <p class="mb-1">
                        {{ $t('card_validate_order_component.desired_delivery_date') }}:
                        <span class="font-weight-bold">{{ computedDate }}</span>
                    </p>
                </v-card-text>

                <v-card-text>
                    <p class="mb-0">
                        {{ $t('card_validate_order_component.confirm_valid_order') }}
                    </p>
                </v-card-text>

                <v-card-actions class="pb-4">
                    <v-spacer/>
                    <v-btn
                        class="font-weight-bold"
                        text
                        @click="dialog_overview_order = false"
                    >
                        {{ $t('global.cancel') }}
                    </v-btn>
                    <v-btn
                        class="font-weight-bold btn-secondary-loader"
                        :loading="loading_edit"
                        text
                        :disabled="disable_btn"
                        @click="validateOrder()"
                    >
                        <span class="secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import OrderCockpitRepository from "@/repositories/OrderCockpitRepository";

export default {
    name: 'CardValidateOrderComponent',
    props: {
        products: {
            type: Array,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
    },
    computed: {
        computedDate() {
            return this.desired_date_delivery ? this.moment(this.desired_date_delivery).format('DD MMMM YYYY') : '';
        },
        valid() {
            return this.desired_date_delivery !== '' && this.selected_products !== '' && this.order_number !== '';
        },
        priceCalculator() {
            if (this.selected_products.price) {
                return !isNaN(this.selected_products.price) ? this.numberWithSpaces((this.selected_products.price * this.order.quantity).toFixed(2)) + ' €' : '';
            }
            return '';
        },
        allowedDates() {
            return val => !this.order.dates_limit.day_off.includes(val);
        }
    },
    data() {
        return {
            desired_date_delivery: '',
            date_picker: false,
            dialog_overview_order: false,
            dialog_cancel_order: false,
            selected_products: '',
            purchase_order: '',
            purchase_order_file: null,
            comment: '',
            disable_btn: false,
            loading_edit: false
        }
    },
    methods: {
        validateOrder() {
            this.loading_edit = true;
            this.disable_btn = true;
            let formData = new FormData();
            if (this.purchase_order_file) {
                formData.append('purchase_order_file', this.purchase_order_file, this.purchase_order_file.name);
            }

            formData.append('id_liquid', this.selected_products.id_liquid);
            formData.append('purchase_order', this.purchase_order);
            formData.append('desired_date_delivery', this.desired_date_delivery);
            formData.append('comment', this.comment);

            OrderCockpitRepository.validateOrderCockpit(this.order.id_order, formData).then(() => {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation');
                this.showSnackbar('success', this.$t('card_validate_order_component.success_valid_order', {order_number: this.order.order_number }));
                this.navigateTo('orders_cockpit');
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.disable_btn = false;
            });
        },

        deleteOrder() {
            OrderCockpitRepository.deleteOrderCockpitById(this.order.id_order).then(() => {
                this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation');
                this.showSnackbar('success', this.$t('card_validate_order_component.success_cancel_order'));
                this.navigateTo('orders_cockpit');
            }).catch((error) => {
                this.manageError(error);
            });
        }
    }
}
</script>