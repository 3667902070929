<template>
    <div id="card-order-details-component">
        <v-card elevation="0">
            <v-card-title>
                <chips-last-statement
                    :danger="345600"
                    :date="order.date_creation"
                    :warning="172800"
                    :previous_text="$t('chips_last_statement.send')"
                />
            </v-card-title>

            <v-card-text class="text-center">
                <display-liquid-level-component
                    :actual_volume="order.asset.actual_volume"
                    :id_asset="order.asset.id_asset"
                    :show_indicator="false"
                    :volume_total="order.asset.volume"
                    :type_asset="order.asset.type_asset"
                    class="my-2"
                />

                <div class="my-1">
                    <span class="font-weight-bold">{{ $t('card_order_details_component.asset') }}</span><br>
                    <span>{{ order.asset.name_asset }}</span>
                </div>

                <div class="my-1">
                    <span class="font-weight-bold">{{ $t('card_order_details_component.address_postal') }}</span><br>
                    <span>{{ order.asset.address }}</span>
                </div>

                <div class="my-1">
                    <span class="font-weight-bold">{{ $t('card_order_details_component.quantity') }}</span><br>
                    <span>{{ order.quantity }} m³</span>
                </div>

                <div class="my-1">
                    <span class="font-weight-bold">{{ $t('card_order_details_component.price_liquid') }}</span><br>
                    <span>{{ showPrice }} </span>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import DisplayLiquidLevelComponent from "../Equipment/LiquidLevelComponent/DisplayLiquidLevelComponent";
import ChipsLastStatement from "@/components/Global/ChipsLastStatement";

export default {
    name: "CardOrderDetailsComponent",
    components: {DisplayLiquidLevelComponent, ChipsLastStatement},
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        showPrice() {
            return !isNaN(this.order.product_price.price) && this.order.product_price.price ? this.order.product_price.price + ' €/m³' : this.order.product_price.cotation_method;
        }
    }
}
</script>
